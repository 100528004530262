var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("Top")]),
        _c("div", [_vm._v("mail:" + _vm._s(_vm.mail))])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }