<template lang="pug">
  div
    .container
      h1 {{title}}
      router-link(to="/") Top
      div mail:{{mail}}
      //- //- Button trigger modal
      //- button.btn.btn-primary(type="button" data-toggle="modal" data-target="#exampleModal") Launch demo modal
      //- //- Modal
      //- #exampleModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
      //- .modal-dialog.role="document"
      //-   .modal-content
      //-     .modal-header
      //-       h5.modal-title#exampleModalLabel Modal title
      //-       button.close(type="button" data-dismiss="modal" aria-label="Close")
      //-         span(aria-hidden="true") &times;
      //-     .modal-body ...
      //-     .modal-footer
      //-       button.btn.btn-secondary(type="button" data-dismiss="modal") Close
      //-       button.btn.btn-primary(type="button") Save changes
</template>

<script>
export default {
  // データオブジェクト
  data() {
    return {
      title: '',
      mail: this.$route.params.mail
    }
  },
  // 算出プロパティ
  computed: {
  },
  // メソッド
  methods: {
  },
  // コンポーネント
  components: {
  },
  // インスタンス作成後
  created() {
    console.log('created')
    console.log(this.$route)
  },
  // マウント後
  mounted() {
    console.log('mounted')
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      console.log('nextTick')
    })
  },
  // データ変更後
  updated() {
    console.log('updated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    console.log('beforeDestroy')
  }
}
</script>